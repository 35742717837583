<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="drawer-bar auto">
        <div class="fl">
          <span class="font16"><b>各系统访问量统计</b></span>
        </div>
        <div class="fr">
          <custom-date-picker v-model="date" @getDate="getDate" @getMode="getMode"></custom-date-picker>
        </div>
      </div>
      <div class="department-content auto">
        <div class="box auto">
          <div class="box-title auto">
            <div class="fl">各系统访问量（Top 10）</div>
          </div>
          <div class="box-con auto">
            <a-spin :spinning="chartLoading">
              <div style="width: 500px;">
                <echarts-base :data="chartData" :height="300"></echarts-base>
              </div>
            </a-spin>
          </div>
        </div>
        <div class="box box2 auto">
          <div class="box-title auto">
            <div class="fl">系统登录统计</div>
          </div>
          <div class="box-con auto no-bor">
            <div class="m-t-10">
              <a-spin :spinning="tableLoading">
                <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" @change="changeTable">
                  <span slot="action" slot-scope="text,record,index">{{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}</span>
                </a-table>
              </a-spin>
            </div>
            <div class="m-t-10">
              <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size"></custom-page>
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
import { Data as DataController } from '@/controller'
export default {
  data () {
    return {
      date: [],
      chartData: '',
      chartFilter: {
        mode: 'week',
        startDate: '',
        endDate: ''
      },
      chartLoading: false,
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          key: 'action'
        },
        {
          title: '系统名称',
          dataIndex: 'servicename',
          scopedSlots: { customRender: 'servicename' },
          key: 'servicename'
        },
        {
          title: '登录人次',
          dataIndex: 'logincount',
          scopedSlots: { customRender: 'logincount' },
          key: 'logincount',
          sorter: true,
          // sortOrder: 'descend',
          sortDirections: ['ascend', 'descend']
        }
      ],
      tabledata: [],
      tableLoading: false,
      filter: {
        order: 'desc',
        current_page: 1,
        page_size: 10
      },
      total: 0
    }
  },
  methods: {
    changeTable (pagination, filters, sorter, { currentDataSource }) {
      // console.log(sorter)
      this.filter['current_page'] = 1
      if (sorter.order) {
        var word = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.filter['order'] = word
        // this.filter['order'] = sorter.columnKey + ' ' + word
      } else {
        this.filter['order'] = ''
      }
      this.getLoginSummaryFailed()
    },
    getMode (mode) {
      this.chartFilter.mode = mode
    },
    getDate (date) {
      // console.log('date', date)
      this.chartFilter.startDate = date[0]
      this.chartFilter.endDate = date[1]
      this.getLoginSummary()
      this.getLoginSummaryFailed()
    },
    async getLoginSummary () {
      this.chartLoading = true
      let res = await System.getServiceaccessSummary(this.chartFilter)
      this.chartLoading = false
      if (!res['code']) {
        this.chartData = DataController.System.Pie7(res['data'].length ? res['data'] : '')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getLoginSummaryFailed()
    },
    async getLoginSummaryFailed () {
      this.tableLoading = true
      let form = Object.assign(this.chartFilter, this.filter)
      let res = await System.getServiceaccessSummaryTimes(form)
      this.tableLoading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.total = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    // this.getLoginSummary()
    // this.getLoginSummaryFailed()
  },
  mounted () {
    // this.chartData =  {
    //     title: {
    //         text: '访问次数',
    //         subtext: '413216546',
    //         left: 'center',
    //         top: '38%',
    //         textStyle: {
    //           color: 'rgba(0,0,0,0.60)',
    //           fontSize: 14
    //         },
    //         subtextStyle: {
    //           color: 'rgba(0,0,0,0.85)',
    //           fontSize: 14
    //         }
    //     },
    //     tooltip: {
    //         trigger: 'item'
    //     },
    //     legend: {
    //         right: '0%',
    //         top: 'center',
    //         orient: 'vertical',
    //         itemWidth: 8,
    //         itemHeight: 8,
    //         icon: 'circle',
    //         textStyle: {
    //           color: 'rgba(0,0,0,0.85)',
    //           fontSize: 12
    //         }
    //     },
    //     series: [
    //         {
    //             name: '注册人数',
    //             type: 'pie',
    //             top: '0%',
    //             radius: ['58%', '70%'],
    //             avoidLabelOverlap: false,
    //             label: {
    //                 show: false
    //             },
    //             emphasis: {
    //                 label: {
    //                     show: false,
    //                     fontSize: '40',
    //                     fontWeight: 'bold'
    //                 }
    //             },
    //             labelLine: {
    //                 show: false
    //             },
    //             data: [
    //                 {value: 1048, name: '搜索引擎搜索引擎'},
    //                 {value: 735, name: '直接访问'},
    //                 {value: 580, name: '邮件营销搜索引擎'},
    //                 {value: 484, name: '联盟广告'},
    //                 {value: 300, name: '视频广告'}
    //             ]
    //         }
    //     ]
    // }
  }
}
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}
</style>
